import {sendTrackingDataForNextPI} from "./tracking";

const blankRedirectClass = 'js_blank';

function executeRedirect(linkTarget, uri) {
    if (linkTarget.classList.contains(blankRedirectClass)) {
        window.open(uri, '_blank');
    } else {
        window.location = uri;
    }
}

/*                                                                                   */
export function initClickTracking(selector, label) {
    let elems = window.document.querySelectorAll(selector);
    for (let i = 0, n = elems.length; i < n; i++) {
        elems[i].addEventListener('click', function (e) {
            let clickTarget = e.currentTarget || e.target;
            e.preventDefault();
            sendTrackingDataForNextPI({san_Navigation: label, san_Interaction: label});
            executeRedirect(clickTarget, clickTarget.href);
        }, false);
    }

    if (window.o_global.debug.status().activated) {
        console.log(`[nav-imberator] Initiated link tracking for ${elems.length} links (selector: ${selector})`);
    }
}
