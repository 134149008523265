/**
 *
 *
 *
 */
function submitToTracking(dataContainer, fn, type) {
    let debug = window.o_global.debug.status().activated;
    try {
        fn(dataContainer);
        if (debug) {
            console.log(type + " submitting " + JSON.stringify(dataContainer));
        }
    } catch (e) {
        if (window.o_global.debug.status().activated) {
            console.log(e);
        }
    }
}

/**
 *
 *
 */

/*                                                                                    */
export function sendTrackingDataForNextPI(trackingData) {
    if (window.o_tracking.bct && typeof window.o_tracking.bct.trackOnNextPageImpression === 'function') {
        submitToTracking(trackingData, window.o_tracking.bct.trackOnNextPageImpression, "Track on next PI");
    }
}

/**
 *
 *
 */

/*                                                                              */
export function sendEventToTracking(dataContainer) {
    if (window.o_tracking.bct && typeof window.o_tracking.bct.sendEventToTrackingServer === 'function') {
        submitToTracking(dataContainer, window.o_tracking.bct.sendEventToTrackingServer, "Track event");
    }
}

export function sendMergeToTracking(dataContainer) {
    if (o_global && o_global.eventQBus && typeof o_global.eventQBus.emit === 'function') {
        if (o_global.debug.status().activated) {
            console.log('nav tracked ', dataContainer);
        }

        o_global.eventQBus.emit('tracking.bct.addToPageImpression', dataContainer);
    }
}
